
import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Navbar from '../components/navbar/Navbar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme: Theme)=>createStyles({
  toolbar: theme.mixins.toolbar
}));

const MainLayout: React.FC = function(props) {
  const classes = useStyles();

  return (
    <Box>
      <Navbar />
      <div className={classes.toolbar} />
      {props.children}
      <ToastContainer />
    </Box>
  );
};

export default MainLayout;
