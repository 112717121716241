
import axios from 'axios';

const URL_HTML2PDF = process.env.REACT_APP_HTML2PDF_API as string;
const URL_SEND_MAIL = process.env.REACT_APP_SEND_MAIL_API as string;

export async function html2pdf(html: any) {
  try{
    const response = await axios.post(URL_HTML2PDF, {
      s3_prefix: 'bids',
      page_size: 'letter',
      pdf_mode: 'landscape',
      html
    });

    return response.data.data;
  }
  catch(err){
    if(err.response)
      throw new Error(err.response.data.message);
    else
      throw err;
  }
}

export async function sendMail(data: any) {
  try{
    const replyTo = data.replyTo.trim();
    await axios.post(URL_SEND_MAIL, {
      email: replyTo ? [...data.emails, replyTo] : data.emails,
      reply_to: replyTo ? replyTo : undefined,
      subject: data.subject,
      body: data.message,
      attachment: data.attachments
    });
  }
  catch(err){
    if(err.response)
      throw new Error(err.response.data.message);
    else
      throw err;
  }
}
