
import React, { useCallback, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import GeoSuggest, { Suggest } from 'react-geosuggest';
import styles from './styles';

export type PlaceValueType = {formatted_address: string; full_state: string; state: string; postal_code: any; location: { lat: number; lng: number; } };

export type PlaceSearchBarProps = {
  placeholder?: string;
  name?: string;
  value?: PlaceValueType;
  onChange?: (value: PlaceValueType)=>void;
  onBlur?: (value: any)=>void;
  error?: boolean;
  helperText?: any;
  disabled?: boolean;
};

const MAPS_API_KEY = process.env.REACT_APP_MAPS_API_KEY;
const MAP_LIBRARIES = ['places'];

const useStyles = makeStyles(styles);

const PlaceSearchBar: React.FC<PlaceSearchBarProps> = function(props) {
  const {
    placeholder = 'Search...',
    name,
    value,
    onChange = ()=>null,
    onBlur,
    error,
    helperText,
    disabled = false
  } = props;
  const geoSuggest = useRef<GeoSuggest>(null);
  const classes = useStyles({error});
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: MAPS_API_KEY,
    libraries: MAP_LIBRARIES
  });

  const [inputVal, setInputVal] = useState((value && value.formatted_address) || '');

  const handleSuggestSelect = useCallback((suggest: Suggest)=>{
    if(suggest && suggest.gmaps){
      let state = '';
      let postal_code = '';
      let full_state = '';
      suggest.gmaps.address_components.forEach(comp=>{
        if(comp.types.indexOf('administrative_area_level_1') >= 0){
          state = comp.short_name;
          full_state = comp.long_name;
        }
        if(comp.types.indexOf('postal_code') >= 0){
          postal_code = comp.short_name;
        }
      });
      onChange({
        formatted_address: suggest.gmaps.formatted_address,
        state,
        full_state,
        postal_code,
        location: suggest.location
      });
      geoSuggest.current?.update(suggest.gmaps.formatted_address);
    }

  }, [onChange]);

  return (
    <>
      {isLoaded && (
        <>
          <GeoSuggest
            ref={geoSuggest}
            country="US"
            placeholder={placeholder}
            name={name}
            onBlur={onBlur}
            value={inputVal}
            initialValue={inputVal}
            onChange={val=>setInputVal(val)}
            onSuggestSelect={handleSuggestSelect}
            className={classes.geoSuggestContainer}
            suggestsClassName={classes.geoSuggests}
            suggestItemClassName={classes.geoSuggestItem}
            suggestsHiddenClassName={classes.geoSuggestsHidden}
            inputClassName={classes.geoSuggestInput}
            disabled={disabled}
          />
          {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </>
      )}
      {isLoaded && !!value && (
        <GoogleMap
          mapContainerStyle={{
            height: "500px"
          }}
          options={{
            center: value && value.location,
            zoom: 18,
            mapTypeId: 'satellite'
          }}
        >
          <Marker
            position={value?.location as any}
          />
        </GoogleMap>
      )}
      {!isLoaded && !loadError && (
        <CircularProgress color="secondary" />
      )}
    </>
  );
};

export default PlaceSearchBar;
