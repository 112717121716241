
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { sendMail } from '../../services/api';
import { getData, generateBidPdf, generateBid } from '../../services/solar-bid';
import { makeStyles } from '@material-ui/core/styles';
import { Modal } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import BidForm, { BidFormData, BidFormRef } from '../../components/bid-form/BidForm';
import CheckoutForm, { CheckoutFormRef } from '../../components/bid-form/CheckoutForm';
import EmailForm from '../../components/email-form/EmailForm';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailMessageTemplate from '../../templates/solar-bid-email';
import styles from '../../components/bid-form/styles';
import axios from 'axios';

const useStyles = makeStyles(styles);

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useModalStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const NewBidPage: React.FC = function() {
  const bidForm = useRef<BidFormRef>(null);
  const checkoutForm = useRef<CheckoutFormRef>(null);

  const [loading, setLoading] = useState(true);
  const [rawData, setRawData] = useState<any>(null);
  const [bidFormData, setBidFormData] = useState<BidFormData | null>(null);
  const [pdfUri, setPdfUri] = useState<string>('');
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [saveData, setSaveData] = useState(false);
  const [failedSave, setFailedSave] = useState(true);
  const [checkout, setCheckout] = useState(false);
  const [showPdfButton, setShowPdfButton] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [product, setProduct] = useState({variants: [{id: ''}]});
  const modalClasses = useModalStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();

  useEffect(()=>{
    getData()
      .then((data: any)=>{
        setRawData(data);
        setLoading(false);
      })
      .catch(err=>{
        console.log(err);
        alert(err.message);
      })
    ;
  }, []);

  const getJsonData = useCallback(async (inputData) => {
    var data = {}
    if(inputData) {
      const system_data = await generateBid(inputData);
      var address = inputData.address.formatted_address.split(', ')
      var count = address.length;
      const json_data = { "first_name": inputData.first_name, "last_name": inputData.last_name, "email": inputData.email, "phone": inputData.phone, "address1": address[0],  "city": address[count-3], "province": inputData.address.state, "country": "US", "province_code": inputData.address.state, "country_code": "US", "country_name": address[count-1], "phone_address": inputData.phone, zip: inputData.address.postal_code}
      var meta_data = {'metafields': {...system_data, ...{ 'data': inputData}}}
      data = {...json_data, ...meta_data};
    }
    return data

  }, [])

  const handleBidFormSubmit = useCallback(async (inputData)=>{
    try{
      setLoading(true);
      // setFailedSave(false)
      setBidFormData(inputData);
      const jsonData = await getJsonData(inputData);
      const headers = {
        'Content-Type': 'application/json',
      }
      // console.log(jsonData);

      await axios.post(process.env.REACT_APP_API_URL + "/customer/create", jsonData, {
        headers: headers
      })
      .then(res => {
        console.log('Api data', res);
        if (res) {
          if(res.status === 201) {
            res.data.message ? toast.success(res.data.message) : toast.success("Customer Created successfully!")
            setSaveData(true)
            setFailedSave(false)
            // setShowPdfButton(false);
          } else if(res.status === 200) {
            res.data && res.data.data.errors ? toast.error(JSON.stringify(res.data.data.errors)) : toast.warn(res.data.message)
            setSaveData(false);
            // setShowPdfButton(false);
          } else {
            res.data && res.data.data.errors ? toast.error(JSON.stringify(res.data.data.errors)) : toast.warn(res.data.message)
            setSaveData(false)
          }
        } })
      .catch(error => console.error(error))
       // setSaveData(true)
    }
    catch(err){
      console.log(err);
      alert(err.message);
    }
    finally{
      setLoading(false);
    }
  }, [getJsonData]);

  const handleGeneratePdf = useCallback(async (inputData)=>{
    try{
      setLoading(true);
      const pdfUri = await generateBidPdf(bidFormData);
      setPdfUri(pdfUri);
      setCheckout(true)
      setShowPdfButton(false);
    }
    catch(err){
      console.log(err);
      alert(err.message);
    }
    finally{
      setLoading(false);
    }
  }, [bidFormData]);

  const handleCheckout = useCallback(async (inputData) => {
    const headers = {
      'Content-Type': 'application/json',
    }
    // const item_id = process.env.REACT_APP_PRODUCT ? process.env.REACT_APP_PRODUCT : "5638997639323"
    await axios.get(process.env.REACT_APP_API_URL + "/product/" + process.env.REACT_APP_PRODUCT, {
      headers: headers
    })
    .then(res => {
      console.log('Api data product', res);
      if (res) {
        if(res.status === 201 && res.data) {
          setProduct(res.data.data.product);
          const product_data = res.data.data.product;
          if (bidFormData && product_data.variants[0].id) {

            var variant_id = product_data.variants[0].id;
            var bid_address = bidFormData.address;
            console.log(bidFormData.address);

            var address = bid_address.formatted_address.split(', ')
            var count = address.length;
            var locality = '';
            var cart_url = process.env.REACT_APP_SITE_CART_URL + "/" + variant_id + ":1?checkout[email]=" + bidFormData.email + "&checkout[shipping_address][first_name]=" + bidFormData.first_name + "&checkout[shipping_address][last_name]=" + bidFormData.last_name + " &checkout[shipping_address][country]=" + address[count-1] + "&checkout[shipping_address][address1]=" + address[0] + "&checkout[shipping_address][city]=" + address[count-3] + "&checkout[shipping_address][zip]=" + bidFormData.address.postal_code + "&checkout[shipping_address][province]=" + bidFormData.address.state + "&checkout[shipping_address][address2]=" + locality;
            window.open(cart_url, "_blank");
            setCheckout(false);
          } else {
            toast.error("product not found in a shopify store");
          }
          // setModalShow(true);
        }
      } })
    .catch(error => console.error(error))
  }, [bidFormData, product]);

  const handleClose = useCallback(async (inputData) => {
    setModalShow(false);
    setCheckout(false);
  }, []);

  const handleCheckoutFormSubmit = useCallback(async (inputData) => {
    setModalShow(false);
    setCheckout(false);
    // setShowPdfButton(true);
    // const headers = {
    //   'Content-Type': 'application/json',
    // }
    // // const item_id = process.env.REACT_APP_PRODUCT ? process.env.REACT_APP_PRODUCT : "5638997639323"
    // await axios.get(process.env.REACT_APP_API_URL + "/product/" + process.env.REACT_APP_PRODUCT, {
    //   headers: headers
    // })
    // .then(res => {
    //   console.log('Api data product', res);
    //   if (res) {
    //     if(res.status === 201 && res.data) {
    //       setProduct(res.data.data.product);
    //       setCheckout(true);
    //       setModalShow(true);
    //     }
    //   } })
    // .catch(error => console.error(error))
  }, [])

  const handleStartOver = useCallback(()=>{
    if(bidForm.current){
      setBidFormData(null);
      setPdfUri('');
      setCheckout(false);
      setSaveData(false);
      setFailedSave(true);
      bidForm.current.reStart(true);
    }
  }, []);

  const handleGoBack = useCallback(()=>{
    if(bidForm.current){
      setBidFormData(null);
      setPdfUri('');
      setFailedSave(true);
      setSaveData(false);
      bidForm.current.reStart(false);
    }
  }, []);

  const handleEmailSend = useCallback((emailData)=>{
    setOpenEmailDialog(false);
    setLoading(true);

    emailData.replyTo = bidFormData?.salesRep.email;
    sendMail(emailData)
      .then(()=>{
        alert('Email sent!');
        setLoading(false);
      })
      .catch(err=>{
        console.log(err);
        alert(err.message);
      })
    ;
  }, [bidFormData]);

  // Flags
  const generatedPdf = !!pdfUri;

  const body = (
    <div style={modalStyle} className={modalClasses.paper}>
      <h2 id="simple-modal-title">Payment</h2>
      <p id="simple-modal-description">
        All transactions are secure and encrypted.
      </p>
      <CheckoutForm
        ref={checkoutForm}
        onSubmit={handleCheckoutFormSubmit}
        bidFormData={bidFormData}
        viewOnly={generatedPdf}
        saveRecord={saveData}
        failedSave={failedSave}
        product={product}
      />
    </div>
  );

  return (
    <>
      <Box pt={5} pb={20}>
        <Container maxWidth="md">
          <Paper elevation={1}>
            <Box p={2}>
              <Typography variant="h3" align="center">New bid</Typography>
              {rawData !== null && (
                <BidForm
                  ref={bidForm}
                  roofs={rawData['roofs']}
                  panels={rawData['panels']}
                  inverters={rawData['inverters']}
                  batteries={rawData['batteries']}
                  financing={rawData['financing']}
                  employees={rawData['employees']}
                  onSubmit={handleBidFormSubmit}
                  disabled={loading}
                  viewOnly={generatedPdf}
                  saveRecord={saveData}
                  failedSave={failedSave}
                />
              )}


              {(saveData && !generatedPdf) && (
                <div className={classes.actionsContainer}>
                  <Grid container spacing={1} justify="flex-end">
                    <Grid item>
                      <Button
                        className={classes.submitBtn}
                        size="large"
                        variant="outlined"
                        disabled={loading}
                        color="primary"
                        onClick={handleGeneratePdf}
                      >
                        Generate Pdf
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              )}
              { (generatedPdf) && (
                <>
                  {!!pdfUri && <iframe title="Solar bid document" height={650} style={{display: 'block', width: '100%'}} src={pdfUri} />}
                  <div style={{ marginBottom: 16 }} />
                  <Grid container spacing={1} justify="center">
                    {/* <Grid item>
                      <Button
                        variant="outlined"
                        size="large"
                        onClick={()=>setOpenEmailDialog(true)}
                        disabled={loading}
                      >
                        Download
                      </Button>
                    </Grid> */}
                    <Grid item>
                      <Button
                        size="large"
                        variant="text"
                        disabled={loading}
                        onClick={handleStartOver}
                      >
                        Start Over
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        size="large"
                        variant="outlined"
                        disabled={loading}
                        onClick={handleGoBack}
                      >
                        Go Back
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        onClick={()=>setOpenEmailDialog(true)}
                        disabled={loading}
                      >
                        Send over email
                      </Button>
                    </Grid>
                    {(checkout &&
                      <Grid item>
                        <Button
                          className={classes.submitBtn}
                          size="large"
                          variant="outlined"
                          disabled={loading}
                          color="primary"
                          onClick={handleCheckout}
                        >
                          Checkout
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
              {loading && (
                <Box textAlign="center" p={8}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
            {loading && <LinearProgress />}
          </Paper>
        </Container>
      </Box>

      {generatedPdf && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={openEmailDialog}
          onClose={()=>setOpenEmailDialog(false)}
        >
          <DialogTitle>Send email</DialogTitle>
          <DialogContent>
            <EmailForm
              emails={bidFormData ? [bidFormData.email as string] : undefined}
              subject="Your solar bid"
              message={emailMessageTemplate(bidFormData)}
              attachments={pdfUri ? [pdfUri as string] : undefined}
              disabled={loading}
              onSubmit={handleEmailSend}
            />
          </DialogContent>
          <DialogActions />
        </Dialog>
      )}
    </>
  );
};


export default NewBidPage;
