
import React, { useState, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Divider, Typography } from '@material-ui/core';

export type AverageBill = {usage: number; payment: number;};

type UsageInputControlProps = {
  onBlur?: (e: any)=>void;
  error?: boolean;
  helperText?: any | {usage: string; payment: string;};
  value?: AverageBill;
  monthlyBills?: any;
  onChange?: (value: AverageBill)=>void,
  onMonthlyBillsChange?: (value: any)=>void
};

const UsageInputControl: React.FC<UsageInputControlProps> = function(props){
  const {
    onBlur = ()=>null,
    error,
    helperText,
    value = {usage: 0, payment: 0},
    monthlyBills,
    onChange = ()=>null,
    onMonthlyBillsChange = ()=>null
  } = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [calculatedAvgBill, setCalculatedAvgBill] = useState({usage: 0, payment: 0});

  useEffect(()=>{
    // Calculate Average
    const usageArr = Object.values(monthlyBills).map((obj: any) => obj.usage).filter(val=>!!val);
    const paymentArr = Object.values(monthlyBills).map((obj: any) => obj.payment).filter(val=>!!val);
    const avgUsage = (usageArr.reduce((sum, val)=>{return sum + val}, 0) / usageArr.length) || 0;
    const avgPayment = (paymentArr.reduce((sum, val)=>{return sum + val}, 0) / paymentArr.length) || 0;
    setCalculatedAvgBill({
      usage: +avgUsage.toFixed(2),
      payment: +avgPayment.toFixed(2)
    });
  }, [monthlyBills]);

  const handleChange = useCallback((ev)=>{
    const fieldName = ev.target.name;
    const fieldValue = ev.target.value;

    onChange({...value, [fieldName]: +fieldValue})
  }, [value, onChange]);

  const monthlyInputChangeHandler = (month: string) => (ev: any) => {
    const fieldName = ev.target.name;
    const value = ev.target.value;

    const bill = monthlyBills[month] || {};
    bill[fieldName] = parseFloat(value);

    onMonthlyBillsChange({...monthlyBills, [month]: bill});
  };

  const handleUseValue = useCallback(()=>{
    onChange(calculatedAvgBill);
    setDialogOpen(false);

  }, [calculatedAvgBill, onChange]);

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            label="Usage (kW)"
            type="number"
            name="usage"
            onBlur={onBlur}
            error={error}
            helperText={helperText && helperText.usage}
            value={value.usage}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            label="Payment ($)"
            type="number"
            name="payment"
            onBlur={onBlur}
            error={error}
            helperText={helperText && helperText.payment}
            value={value.payment}
            onChange={handleChange}
            fullWidth
          />
        </Grid>
        <Grid item>
          <Button
            size="small"
            color="primary"
            onClick={()=>{setDialogOpen(true)}}
          >
            Calculate from bills
          </Button>
        </Grid>
      </Grid>
      <Dialog
        open={dialogOpen}
        fullWidth
        maxWidth="sm"
        onClose={()=>setDialogOpen(false)}
      >
        <DialogTitle>Calculate average usage</DialogTitle>
        <DialogContent>
          {['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'].map(month=>(
            <Grid key={month} container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label={`Usage (${month})`}
                  name="usage"
                  type="number"
                  value={monthlyBills[month]?.usage || 0}
                  onChange={monthlyInputChangeHandler(month)}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={`Payment (${month})`}
                  name="payment"
                  type="number"
                  value={monthlyBills[month]?.payment || 0}
                  onChange={monthlyInputChangeHandler(month)}
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          ))}
          <div style={{marginBottom: 16}} />
          <Divider  />
          <div style={{marginBottom: 16}} />
          <Typography>Usage: {calculatedAvgBill.usage.toFixed(2)}</Typography>
          <Typography>Payment: {calculatedAvgBill.payment.toFixed(2)}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleUseValue} variant="contained" color="primary">Use values</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UsageInputControl;
