import React, { useState, useCallback, useImperativeHandle, forwardRef, useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useFormik } from 'formik';
import initialValues from './initial-values';
import validationStripeSchema from './validation-stripe';
import validationSchema from './validation-schema';
import styles from './styles';
import * as Yup from 'yup';

export type CheckoutFormData = {
  card_number: string;
  name: string;
  expiration_date: string;
  security_code: number;
};

export interface CheckoutFormProps {
  bidFormData: any;
  onSubmit?: (data: CheckoutFormData)=>void;
  viewOnly?: boolean;
  saveRecord?: boolean;
  failedSave?: boolean;
  disabled?: boolean;
  product?: any;
};
export type CheckoutFormRef = {reStart: (clearValues?: boolean)=>void};

const useStyles = makeStyles(styles);

const CheckoutForm: React.RefForwardingComponent<CheckoutFormRef, CheckoutFormProps> = function(props, ref) {

  const {
    bidFormData,
    product,
    onSubmit = ()=>null,
    disabled = false,
    viewOnly = false,
    saveRecord = true,
    failedSave = true

  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const [fullName, setFullName] = useState('');

  const calculatedInitialValues = useMemo(()=>{
    let defaultValues: any = {};
    setFullName(bidFormData.first_name + " " + bidFormData.last_name)
    return {...initialValues, ...defaultValues} as CheckoutFormData;

  }, [bidFormData]);

  const formik = useFormik({
    initialValues: calculatedInitialValues,
    validationSchema: Yup.object().shape({
      card_number: Yup.string().required('Required.').matches(/^[0-9]{16}$/, 'Please add valid card number'),
      name: Yup.string().required('Required.'),
      security_code: Yup.string().required('Required').matches(/^[0-9]{3}(?:-[0-9]{4})?$/, 'Must be exactly 3 or 4 code'),
      expiration_date: Yup.string().required('Required').matches(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/, "Please add valid date")
    }),
    onSubmit: (values)=>{
      // setActiveStep(prevStep=>prevStep+1);
      onSubmit(values);
    }
  });

  return (
    <div>
      <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{fullName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Contact</TableCell>
              <TableCell>{bidFormData.email} - {bidFormData.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ship to</TableCell>
              <TableCell>{bidFormData.address.formatted_address}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Total Price</TableCell>
              <TableCell>USD - ${(product && product.variants.length > 0) ? product.variants[0].price : 0.00}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <br/>
      <br/>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Card Number"
              {...formik.getFieldProps('card_number')}
              fullWidth
              disabled={disabled}
              error={!!(formik.touched['card_number'] && formik.errors['card_number'])}
              helperText={formik.touched['card_number'] && formik.errors['card_number']}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Name on card"
              value={fullName}
              {...formik.getFieldProps('name')}
              fullWidth
              disabled={disabled}
              error={!!(formik.touched['name'] && formik.errors['name'])}
              helperText={formik.touched['name'] && formik.errors['name']}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Expiration Date (MM/YY)"
              {...formik.getFieldProps('expiration_date')}
              fullWidth
              disabled={disabled}
              error={!!(formik.touched['expiration_date'] && formik.errors['expiration_date'])}
              helperText={formik.touched['expiration_date'] && formik.errors['expiration_date']}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Security Code"
              {...formik.getFieldProps('security_code')}
              fullWidth
              disabled={disabled}
              error={!!(formik.touched['security_code'] && formik.errors['security_code'])}
              helperText={formik.touched['security_code'] && formik.errors['security_code']}
            />
          </Grid>
        </Grid>
        <div className={classes.actionsContainer}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item>
              <Button
                className={classes.submitBtn}
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                Payment
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
};

export default forwardRef(CheckoutForm);
