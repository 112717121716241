
export default {
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  salesRep: '',
  address: undefined,
  roof_direction: 's',
  utility_provider: '',
  offset: 1,
  financing_type: 'salal_20_899', // TODO: Should be flagged as 'default: true' in the JSON retrieved from server, instead.
  kit_type: 'grid-tied',
  roof_type: '',
  panel_type: '',
  panel_adjustment: 0,
  inverter_type: '',
  add_batteries: false,
  battery_type: '',
  system_size_type: 'usage',
  system_size: {
    key: 'panels',
    value: 0
  },
  sq_footage: 0,
  average_bill: {
    usage: 0,
    payment: 0
  },
  card_number: '',
  name: '',
  expiration_date: '',
  security_code: 0,
};
