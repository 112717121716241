
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    textAlign: 'center'
  },
  stop: {
    stroke: theme.palette.common.black,
    strokeWidth: 2,
    '&.active': {
      stroke: theme.palette.primary.main,
      strokeWidth: 4
    }
  },
  anchorPoint: {
    fill: theme.palette.common.black,
    '&:hover': {
      cursor: 'pointer',
      stroke: theme.palette.common.black,
      strokeWidth: 4
    },
    '&.active': {
      fill: 'transparent',
      stroke: theme.palette.primary.main,
      strokeWidth: 4,
      '&:hover': {
        stroke: theme.palette.primary.main
      }
    }
  },
  label: {
    fontWeight: 700,
    '&.active': {
      fill: theme.palette.primary.main
    }
  },
  needle: {
    fill: theme.palette.primary.main
  }
});

export default styles;