import React, { useState, useCallback, useImperativeHandle, forwardRef, useMemo } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import { useFormik } from 'formik';
import PlaceSearchBar, { PlaceValueType } from '../place-search-bar/PlaceSearchBar';
import AverageBillInput, { AverageBill } from '../average-bill-input/AverageBillInput';
import DirectionInput from '../direction-input/DirectionInput';
import SystemSizeInput, { SystemSize } from '../system-size-input/SystemSizeInput';
import FormDataSummary from './FormDataSummary';
import initialValues from './initial-values';
import validationSchema from './validation-schema';
import validationStripeSchema from './validation-stripe';
import styles from './styles';

export type BidFormData = {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  salesRep: any;
  address: PlaceValueType;
  roof_direction: string;
  utility_provider?: string;
  offset?: number;
  financing_type: string;
  kit_type: string;
  roof_type: string;
  panel_type: string;
  panel_adjustment?: number;
  inverter_type: string;
  add_batteries?: boolean;
  battery_type?: string;
  system_size_type?: string;
  system_size?: SystemSize;
  sq_footage?: number;
  average_bill?: AverageBill;
  card_number: string;
  name: string;
  expiration_date: string;
  security_code: number;
};

type MenuOption = {label: string; value: any; default?: boolean; availableStates?: string[];};

type BidFormProps = {
  roofs: any;
  panels: any;
  inverters: any;
  batteries: any;
  financing: any;
  employees: any[];
  onSubmit?: (data: BidFormData)=>void;
  disabled?: boolean;
  viewOnly?: boolean;
  saveRecord?: boolean;
  failedSave?: boolean;
};

export type BidFormRef = {reStart: (clearValues?: boolean)=>void};

const stepFieldsMap: any = {
  0: ['first_name', 'last_name', 'phone', 'email', 'salesRep'],
  1: ['address', 'roof_direction'],
  2: ['utility_provider', 'offset', 'kit_type', 'roof_type', 'panel_type', 'panel_adjustment', 'inverter_type', 'battery_type'],
  3: ['financing_type', 'system_size', 'sq_footage', 'average_bill'],
  4: []
};

const stepLabels: string[] = [
  'Personal information',
  'Location & geometry',
  'System details',
  'Finance & usage',
  'Submit'
];

const roofDirectionOptions = [
  {value: 'e', label: 'E'},
  {value: 'e-se', label: ''},
  {value: 's-e', label: 'SE'},
  {value: 's-se', label: ''},
  {value: 's', label: 'S'},
  {value: 's-sw', label: ''},
  {value: 's-w', label: 'SW'},
  {value: 'w-sw', label: ''},
  {value: 'w', label: 'W'},
  {value: 'w-nw', label: ''},
  {value: 'n-w', label: 'NW'},
  {value: 'n-nw', label: ''},
  {value: 'n', label: 'N'},
  {value: 'n-ne', label: ''},
  {value: 'n-e', label: 'NE'},
  {value: 'e-ne', label: ''}
];

const useStyles = makeStyles(styles);

const BidForm: React.RefForwardingComponent<BidFormRef, BidFormProps> = function(props, ref) {
  const {
    roofs,
    panels,
    inverters,
    batteries,
    financing,
    employees = [],
    onSubmit = ()=>null,
    disabled = false,
    viewOnly = false,
    saveRecord = true,
    failedSave = true

  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const formOptions = useMemo(()=>{
    const formOptions: {[key: string]: MenuOption[]} = {};

    formOptions['roofs'] = Object.keys(roofs).map((r: any)=>({ label: roofs[r].name, value: r, default: !!roofs[r].default }));
    formOptions['panels'] = Object.keys(panels).map((p: any)=>({ label: panels[p].name, value: p, default: !!panels[p].default }));
    formOptions['inverters'] = Object.keys(inverters).map((i: any)=>({ label: inverters[i].name, value: i, default: !!inverters[i].default }));
    formOptions['batteries'] = Object.keys(batteries).map((b: any)=>({ label: batteries[b].name, value: b }));
    formOptions['financing'] = Object.keys(financing).reduce((opts, f: any)=>{
      return opts.concat(Object.keys(financing[f].options).map(opt=>{
        return {
          label: financing[f].options[opt].name,
          value: opt,
          default: !!financing[f].options[opt].default,
          availableStates: financing[f].availableStates
        };
      }) as any)
    }, []);
    formOptions['employees'] = employees.map((e: any)=>{
      const menuOption = e.active && e.roles.includes('sales') && {
        label: `${e.firstName} ${e.lastName}`,
        value: e
      };

      if(menuOption)
        return menuOption;

      return undefined;
    }).filter((opt)=>!!opt) as MenuOption[];

    return formOptions;

  }, [batteries, employees, financing, inverters, panels, roofs]);

  const calculatedInitialValues = useMemo(()=>{
    let defaultValues: any = {};
    defaultValues.roof_type = formOptions['roofs'].find(opt=>opt.default)?.value || '';
    defaultValues.panel_type = formOptions['panels'].find(opt=>opt.default)?.value || '';
    defaultValues.inverter_type = formOptions['inverters'].find(opt=>opt.default)?.value || '';
    defaultValues.financing_type = formOptions['financing'].find(opt=>opt.default)?.value || '';

    return {...initialValues, ...defaultValues} as BidFormData;

  }, [formOptions]);

  const formik = useFormik({
    initialValues: calculatedInitialValues,
    validationSchema,
    onSubmit: (values)=>{
      // setActiveStep(prevStep=>prevStep+1);
      onSubmit(values);
    }
  });

  const [activeStep, setActiveStep] = useState(0);
  const [showAdvancedParameters, setShowAdvancedParameters] = useState(false);
  const [monthlyBills, setMonthlyBills] = useState<{[key: string]: any}>({});
  const [tempOffsetVal, setTempOffsetVal] = useState(formik.values['offset']);

  useImperativeHandle(ref, ()=>({
    reStart: (clearValues)=>{
      if(clearValues)
        formik.resetForm();

      setActiveStep(0);
    }
  }), [formik]);

  const getInverterOptions = useCallback((kitType, panelType)=>{
    if(kitType === 'grid-tied-hybrid' || kitType === 'off-grid')
      return formOptions['inverters'].filter(opt=>opt.value === 'solark');
    else if(panelType === 'solaria370')
      return formOptions['inverters'].filter(opt=>opt.value !== 'apSystemsQS1');

    return formOptions['inverters'];

  }, [formOptions]);

  const getFinancingOptions = useCallback((address: PlaceValueType | undefined)=>{
    if(address && address.state)
      return formOptions['financing'].filter(opt=>!opt.availableStates || !!opt.availableStates.find(st=>st.toLowerCase() === address.state.toLowerCase()));
    else
      return formOptions['financing'];

  }, [formOptions]);

  // Custom handlers/callbacks
  const handleNext = useCallback(()=>{
    const fieldsToValidate = stepFieldsMap[activeStep] || [];
    formik.validateForm(formik.values)
      .then((errors: any)=>{
        const errs = fieldsToValidate.reduce((errs: any, field: any)=>{
          if(errors[field])
            errs[field] = errors[field];

          return errs;
        }, {});

        const errFields = Object.keys(errs);
        if(errFields.length === 0)
          setActiveStep(prevStep=>prevStep+1);
        else{
          errFields.forEach(field=>{
            formik.setFieldTouched(field, true, true);
            formik.setFieldError(field, errs[field]);
          });
        }
      })
    ;

  }, [activeStep, formik]);

  const handleAddressChange = useCallback(function(value){
    formik.setFieldValue('address', value, true);
    // Update financing_type if needed
    const financingOptions = getFinancingOptions(value);
    const financing_type = formik.values['financing_type'];
    if(financing_type && !financingOptions.find(opt=>opt.value === financing_type))
      formik.setFieldValue('financing_type', '', true);
  }, [formik, getFinancingOptions]);

  const handleOffsetChange = useCallback(function(ev, newValue){
    setTempOffsetVal(newValue);
  }, []);

  const handleOffsetChangeCommit = useCallback(function(ev, newValue){
    formik.setFieldValue('offset', newValue, true);
  }, [formik]);

  const handleAvgBillChange = useCallback(function(value: AverageBill){
    formik.setFieldValue('average_bill', value, true);
  }, [formik]);

  const handleSystemSizeChange = useCallback(function(value: SystemSize){
    formik.setFieldValue('system_size', value, true);
  }, [formik])

  const handleRoofDirectionChange = useCallback(function(value: string){
    formik.setFieldValue('roof_direction', value, true);
  }, [formik]);

  const handlePanelTypeChange = useCallback(function(ev: any){
    formik.setFieldValue('panel_type', ev.target.value, true);
    // formik.setFieldValue('system_size', { key: 'panels', value: 0 }, true);
    if(ev.target.value === 'solaria370' && formik.values['inverter_type'] === 'apSystemsQS1'){
      formik.setFieldValue('inverter_type', 'enphase', true);
    }
  }, [formik]);

  // Flags
  const showPrevButton = (!viewOnly && activeStep > 0 && activeStep < 5) && failedSave;
  const showNextButton = !viewOnly && activeStep < 4;
  const showSubmitButton = (activeStep >= 4 && !saveRecord );
  const showBatteriesInput = formik.values['inverter_type'] === 'solark';
  const validateRecord = failedSave;

  return (
    <>
      <Typography variant="subtitle1" color="textSecondary" align="center">{stepLabels[activeStep]}</Typography>
      <Stepper alternativeLabel activeStep={activeStep} className={classes.stepper}>
        <Step><StepLabel>{stepLabels[0]}</StepLabel></Step>
        <Step><StepLabel>{stepLabels[1]}</StepLabel></Step>
        <Step><StepLabel>{stepLabels[2]}</StepLabel></Step>
        <Step><StepLabel>{stepLabels[3]}</StepLabel></Step>
        <Step><StepLabel>{stepLabels[4]}</StepLabel></Step>
      </Stepper>
      <form onSubmit={formik.handleSubmit}>
        {/* BEGIN: Step 1 */}
        {activeStep === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="First name"
                {...formik.getFieldProps('first_name')}
                fullWidth
                disabled={disabled}
                error={!!(formik.touched['first_name'] && formik.errors['first_name'])}
                helperText={formik.touched['first_name'] && formik.errors['first_name']}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Last name"
                {...formik.getFieldProps('last_name')}
                fullWidth
                disabled={disabled}
                error={!!(formik.touched['last_name'] && formik.errors['last_name'])}
                helperText={formik.touched['last_name'] && formik.errors['last_name']}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Phone number (US)"
                {...formik.getFieldProps('phone')}
                fullWidth
                disabled={disabled}
                error={!!(formik.touched['phone'] && formik.errors['phone'])}
                helperText={formik.touched['phone'] && formik.errors['phone']}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email address"
                {...formik.getFieldProps('email')}
                fullWidth
                disabled={disabled}
                error={!!(formik.touched['email'] && formik.errors['email'])}
                helperText={formik.touched['email'] && formik.errors['email']}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Sales Representative"
                name="salesRep"
                fullWidth
                value={formik.values['salesRep']}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={disabled}
                select
                error={!!(formik.touched['salesRep'] && formik.errors['salesRep'])}
                helperText={formik.touched['salesRep'] && formik.errors['salesRep']}
              >
                {formOptions['employees'].map(opt=>(
                  <MenuItem key={opt.value.email} value={opt.value}>{opt.label}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        )}
        {/* BEGIN: Step 2 */}
        {activeStep === 1 && (
          <Container maxWidth="md">
            <Grid container direction="column" spacing={8}>
              <Grid item>
                <PlaceSearchBar
                  placeholder="Search address..."
                  name="address"
                  value={formik.values['address']}
                  onChange={handleAddressChange}
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                  error={!!(formik.touched['address'] && formik.errors['address'])}
                  helperText={formik.touched['address'] && formik.errors['address']}
                />
              </Grid>
              <Grid item>
                <Typography align="center" gutterBottom>Average roof direction</Typography>
                <DirectionInput
                  size={isXs ? 380 : 500}
                  options={roofDirectionOptions}
                  name="roof_direction"
                  value={formik.values['roof_direction']}
                  onChange={handleRoofDirectionChange}
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                  error={!!(formik.touched['roof_direction'] && formik.errors['roof_direction'])}
                  helperText={formik.touched['roof_direction'] && formik.errors['roof_direction']}
                />
              </Grid>
            </Grid>
          </Container>
        )}
        {/* BEGIN: Step 3 */}
        {activeStep === 2 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Utility provider"
                {...formik.getFieldProps('utility_provider')}
                fullWidth
                disabled={disabled}
                error={!!(formik.touched['utility_provider'] && formik.errors['utility_provider'])}
                helperText={formik.touched['utility_provider'] && formik.errors['utility_provider']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Roof/Installation type"
                {...formik.getFieldProps('roof_type')}
                select
                fullWidth
                disabled={disabled}
                error={!!(formik.touched['roof_type'] && formik.errors['roof_type'])}
                helperText={formik.touched['roof_type'] && formik.errors['roof_type']}
              >
                {formOptions['roofs'].map(op=>(
                  <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Panel type"
                {...formik.getFieldProps('panel_type')}
                onChange={handlePanelTypeChange}
                select
                fullWidth
                disabled={disabled}
                error={!!(formik.touched['panel_type'] && formik.errors['panel_type'])}
                helperText={formik.touched['panel_type'] && formik.errors['panel_type']}
              >
                {formOptions['panels'].map(op=>(
                  <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Kit type"
                {...formik.getFieldProps('kit_type')}
                select
                fullWidth
                disabled={disabled}
                error={!!(formik.touched['kit_type'] && formik.errors['kit_type'])}
                helperText={formik.touched['kit_type'] && formik.errors['kit_type']}
              >
                <MenuItem value="grid-tied">Grid Tied</MenuItem>
                <MenuItem value="grid-tied-hybrid">Grid Tied Hybrid</MenuItem>
                <MenuItem value="off-grid">Off Grid</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Inverter type"
                {...formik.getFieldProps('inverter_type')}
                select
                fullWidth
                disabled={disabled}
                error={!!(formik.touched['inverter_type'] && formik.errors['inverter_type'])}
                helperText={formik.touched['inverter_type'] && formik.errors['inverter_type']}
              >
                {getInverterOptions(formik.values['kit_type'], formik.values['panel_type']).map(op=>(
                  <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
                ))}
              </TextField>
            </Grid>
            {showBatteriesInput && (
              <Grid item xs={12}>
                <FormControlLabel
                  label="Add batteries"
                  name="add_batteries"
                  checked={formik.values['add_batteries']}
                  onChange={formik.handleChange}
                  disabled={disabled}
                  control={<Switch />}
                />
              </Grid>
            )}
            {showBatteriesInput && formik.values['add_batteries'] && (
              <Grid item xs={12}>
                <TextField
                  label="Batteries"
                  {...formik.getFieldProps('battery_type')}
                  select
                  fullWidth
                  disabled={disabled}
                  error={!!(formik.touched['battery_type'] && formik.errors['battery_type'])}
                  helperText={formik.touched['battery_type'] && formik.errors['battery_type']}
                >
                  {formOptions['batteries'].map(op=>(
                    <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography gutterBottom>Utility offset: <b>{Math.round(Number(tempOffsetVal)*100)}%</b></Typography>
              <div><br /><br /></div>
              <Slider
                min={0}
                max={2}
                step={0.01}
                valueLabelFormat={(val)=><b>{Math.round(val*100)}</b>}
                valueLabelDisplay="auto"
                value={tempOffsetVal}
                onChange={handleOffsetChange}
                onChangeCommitted={handleOffsetChangeCommit}
              />
              {/* <TextField
                label="Utility Offset"
                type="number"
                {...formik.getFieldProps('offset')}
                fullWidth
                disabled={disabled}
                error={!!(formik.touched['offset'] && formik.errors['offset'])}
                helperText={formik.touched['offset'] && formik.errors['offset']}
              /> */}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                label="Advanced parameters"
                onChange={(ev: any)=>setShowAdvancedParameters(ev.target.checked)}
                checked={showAdvancedParameters}
                control={<Switch />}
              />
            </Grid>
            {showAdvancedParameters && (
              <Grid item xs={12}>
                <TextField
                  label="Panel adjustment"
                  type="number"
                  {...formik.getFieldProps('panel_adjustment')}
                  fullWidth
                  disabled={disabled}
                  error={!!(formik.touched['panel_adjustment'] && formik.errors['panel_adjustment'])}
                  helperText={formik.touched['panel_adjustment'] && formik.errors['panel_adjustment']}
                />
              </Grid>
            )}
          </Grid>
        )}

        {/* BEGIN: Step 4 */}
        {activeStep === 3 && (
          <Container maxWidth="sm">
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  label="Financing"
                  {...formik.getFieldProps('financing_type')}
                  select
                  fullWidth
                  disabled={disabled}
                  error={!!(formik.touched['financing_type'] && formik.errors['financing_type'])}
                  helperText={formik.touched['financing_type'] && formik.errors['financing_type']}
                >
                  {getFinancingOptions(formik.values['address']).map(op=>(
                    <MenuItem key={op.value} value={op.value}>{op.label}</MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <div style={{ marginBottom: 16 }} />
                <FormLabel>Usage</FormLabel>
                <RadioGroup row {...formik.getFieldProps('system_size_type')}>
                  <FormControlLabel
                    label="Monthly average"
                    value="usage"
                    control={<Radio />}
                  />
                  <FormControlLabel
                    label="Square footage"
                    value="sqfootage"
                    control={<Radio />}
                  />
                  <FormControlLabel
                    label="Known size"
                    value="known"
                    control={<Radio />}
                  />
                </RadioGroup>
              </Grid>
              {formik.values['system_size_type'] === 'usage' && (
                <Grid item>
                  <AverageBillInput
                    value={formik.values['average_bill']}
                    onChange={handleAvgBillChange}
                    onBlur={formik.handleBlur}
                    monthlyBills={monthlyBills}
                    onMonthlyBillsChange={setMonthlyBills}
                    error={!!(formik.touched['average_bill'] && formik.errors['average_bill'])}
                    helperText={formik.touched['average_bill'] && formik.errors['average_bill']}
                  />
                </Grid>
              )}
              {formik.values['system_size_type'] === 'sqfootage' && (
                <Grid item>
                  <TextField
                    label="Sq. footage"
                    type="number"
                    fullWidth
                    {...formik.getFieldProps('sq_footage')}
                    error={!!(formik.touched['sq_footage'] && formik.errors['sq_footage'])}
                    helperText={formik.touched['sq_footage'] && formik.errors['sq_footage']}
                  />
                </Grid>
              )}
              {formik.values['system_size_type'] === 'known' && (
                <Grid item>
                  <SystemSizeInput
                    wattagePerPanel={panels[formik.values['panel_type']].wattage}
                    value={formik.values['system_size']}
                    onChange={handleSystemSizeChange}
                    // InputStepperProps={{
                    //   stepSize: formik.values['system_size']?.key === 'panels' ? 1 : panels[formik.values['panel_type']].wattage
                    // }}
                    error={!!(formik.touched['system_size'] && formik.errors['system_size'])}
                    helperText={formik.touched['system_size'] && formik.errors['system_size']}
                  />
                </Grid>
              )}
            </Grid>
          </Container>
        )}

        {/* BEGIN: Step 5 */}
        { ((activeStep >= 4 || saveRecord) && !viewOnly ) && (
          <Container maxWidth="sm">
            <Typography variant="h5" gutterBottom><b>Summary</b></Typography>
            <FormDataSummary
              data={{
                ...formik.values,
                panel_type: formOptions['panels'].find(op=>op.value === formik.values['panel_type'])?.label,
                roof_type: formOptions['roofs'].find(op=>op.value === formik.values['roof_type'])?.label,
                inverter_type: formOptions['inverters'].find(op=>op.value === formik.values['inverter_type'])?.label,
                financing_type: formOptions['financing'].find(op=>op.value === formik.values['financing_type'])?.label,
                battery_type: formOptions['batteries'].find(op=>op.value === formik.values['battery_type'])?.label,
              }}
            />
          </Container>
        )}

        {/* BEGIN: Actions container */}
        <div className={classes.actionsContainer}>
          <Grid container spacing={1} justify="flex-end">
            {showPrevButton && (
              <Grid item>
                <Button
                  size="large"
                  onClick={()=>setActiveStep(prevStep=>prevStep-1)}
                  disabled={disabled}
                >
                  {showSubmitButton ? 'Back' : 'Previous'}
                </Button>
              </Grid>
            )}
            {showNextButton && (
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={handleNext}
                  disabled={disabled}
                >
                  Next
                </Button>
              </Grid>
            )}
            {showSubmitButton && (
              <Grid item>
                <Button
                  className={classes.submitBtn}
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={disabled}
                >
                  Save
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </form>
    </>
  );
};

export default forwardRef(BidForm);
