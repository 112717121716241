
import axios from 'axios';
import { html2pdf } from './api';
import solarBidTemplate from '../templates/solar-bid-document';

export async function getData() {
  return await axios.get('https://solar-wholesale.firebaseio.com/.json')
    .then(res => res.data)
  ;
};

export async function generateBidPdf(inputData) {
  const bid = await generateBid(inputData);
  const parsedTemplate = solarBidTemplate({bid, user_input: inputData});
  const pdfInfo = await html2pdf(parsedTemplate);
  return pdfInfo.s3_path;
}

const roofFactorMap = {
  'n': 1.3,
  'n-ne': 1.2625,
  'n-e': 1.225,
  'e-ne': 1.1875,
  'e': 1.15,
  'e-se': 1.1125,
  's-e': 1.075,
  's-se': 1.0375,
  's': 1,
  's-sw': 1.0375,
  's-w': 1.075,
  'w-sw': 1.1125,
  'w': 1.15,
  'w-nw': 1.1875,
  'n-w': 1.225,
  'n-nw': 1.2625,
};

export async function generateBid(inputData) {
  const {
    address,
    roof_type,
    offset = 1, // 1 (default) would be a 100% offset
    roof_direction = 's',
    kit_type,
    panel_type,
    panel_adjustment = 0, // integer of number of panels to add
    inverter_type,
    add_batteries,
    battery_type,
    system_size_type,
    average_bill,
    sq_footage,
    system_size,
    // address_state = 'UT',
    financing_type = 'salal_20_899'

  } = inputData;

  // Size System
  const firebase = await getData();
  const { roofs, panels, inverters, batteries, financing } = firebase;
  const { basePpw, derateFactor, fixedCosts } = firebase.data;

  const roof_factor = roofFactorMap[roof_direction];
  const roof = roofs[roof_type];
  const panel = panels[panel_type];
  const inverter = inverters[inverter_type];
  // const battery = batteries[battery_type];

  let systemSize;

  switch(system_size_type){
    case 'usage': systemSize = await sizeSystem(address.location.lat, address.location.lng); break; // latitude and longitude come from the address and Google Maps API
    case 'sqfootage': systemSize = sqFootageEst(); break;
    case 'known': systemSize = exactSize(system_size.key, system_size.value); break;
    default: systemSize = {kw: 0, panels: 0, inverters: 0}; // TODO: Implement case 'known' system size
  }

  function panelSpecifications(num) {
    if (inverter_type === 'sanctuary') {
      let remainingPanels = num;
      while (remainingPanels > 36) {
        remainingPanels -= 36;
      }
      if (remainingPanels > 26 && remainingPanels % 2 !== 0) {
        num += 1
      }
    }
  }

  function panelCount(systemSize) {
    let numOfPanels = Math.ceil(systemSize / panel.wattage) + panel_adjustment;
    numOfPanels = panelSpecifications(numOfPanels);
    return numOfPanels;
  }

  function inverterCount(numOfPanels, displayedSystemSize) {
    let numInverters;
    if (inverter.type === 'micro') {
      numInverters = numOfPanels;
    } else if (inverter.type === 'string') {
      switch (inverter.maxType) {
        case 'kw': numInverters = Math.ceil(displayedSystemSize / inverter.maxkw); break;
        case 'panels': numInverters = Math.ceil(numOfPanels / inverter.maxPanels); break;
        default: numInverters = 1; break;
      }
    }
    return numInverters;
  }

  async function sizeSystem(latitude, longitude) {
    const sunlight = await axios.get(`https://developer.nrel.gov/api/solar/solar_resource/v1.json?api_key=${process.env.REACT_APP_NREL_API_KEY}&lat=${latitude}&lon=${longitude}`)
      .then(res => res.data.outputs.avg_lat_tilt)
    ;
    // const sunlight =  {annual: 5.37}; // testing version
    const acSystem = (average_bill.usage / 30) / (sunlight.annual || 1/** 1 in-case api returns nothing */); // 30 days in a month
    const dcSystem = acSystem / derateFactor;
    const systemSize = dcSystem * offset * roof_factor;
    const numOfPanels = panelCount(systemSize);
    const displayedSystemSize = (numOfPanels * panel.wattage).toFixed(2);
    const numInverters = inverterCount(numOfPanels, displayedSystemSize);
    return {
      kw: parseFloat(displayedSystemSize),
      panels: numOfPanels,
      inverters: numInverters
    };
  };

  function sqFootageEst() {
    const systemSize = ((sq_footage * 5) / 1000).toFixed(2);
    const numOfPanels = panelCount(systemSize);
    const displayedSystemSize = (numOfPanels * panel.wattage).toFixed(2);
    const numInverters = inverterCount(numOfPanels, displayedSystemSize);
    return {
      kw: parseFloat(displayedSystemSize),
      panels: numOfPanels,
      inverters: numInverters
    };
  };

  function exactSize(inputType, inputValue) {
    let numOfPanels;
    let displayedSystemSize;
    if (inputType === 'panels') {
      numOfPanels = inputValue;
      displayedSystemSize = (numOfPanels * panel.wattage).toFixed(2);
    } else {
      displayedSystemSize = inputValue;
      numOfPanels = Math.round(displayedSystemSize / panel.wattage);
    }
    numOfPanels = panelSpecifications(numOfPanels);
    const numInverters = inverterCount(numOfPanels, displayedSystemSize);
    return {
      kw: parseFloat(displayedSystemSize),
      panels: numOfPanels,
      inverters: numInverters
    };
  }

  function pricing() {
    let price = (systemSize.kw * 1000 * basePpw) + fixedCosts;
    if (panel_type === 'solaria370' && inverter_type === 'solarEdge') {
      price += systemSize.kw * 1000 * 0.1;
    };
    price += price * financing[financing_type.split('_')[0]].options[financing_type].dealerFee;
    if (inverter && inverter.hasOwnProperty('adders')) {
      Object.keys(inverter.adders).forEach(type => {
        if (type === 'fixed') {
          price += inverter.adders[type];
        } else if (type === 'ppi') {
          price += inverter.adders[type] * systemSize.inverters;
        } else if (type === 'ppw') {
          price += inverter.adders[type] * 1000 * systemSize.kw;
        } else if (type === 'ppp') {
          price += inverter.adders[type] * systemSize.panels;
        };
      });
    };
    if (panel && panel.hasOwnProperty('adders')) {
      Object.keys(panel.adders).forEach(type => {
        if (type === 'fixed') {
          price += panel.adders[type];
        } else if (type === 'ppi') {
          price += panel.adders[type] * systemSize.panels;
        } else if (type === 'ppw') {
          price += panel.adders[type] * 1000 * systemSize.kw;
        } else if (type === 'ppp') {
          price += panel.adders[type] * systemSize.panels;
        };
      });
    };
    if (roof && roof.hasOwnProperty('adders')) {
      Object.keys(roof.adders).forEach(type => {
        if (type === 'fixed') {
          price += roof.adders[type];
        } else if (type === 'ppi') {
          price += roof.adders[type] * systemSize.roofs;
        } else if (type === 'ppw') {
          price += roof.adders[type] * 1000 * systemSize.kw;
        } else if (type === 'ppp') {
          price += roof.adders[type] * systemSize.panels;
        };
      });
    };
    if (add_batteries && battery_type) {
      [battery_type].forEach(bat => price += batteries[bat].cost);
    };
    const total = Math.round(price);
    let taxCreditPercent;
    const date = new Date();
    date.setDate(date.getDate() + 30);
    switch (date.getFullYear()) {
      case 2020: taxCreditPercent = 0.26; break;
      case 2021: taxCreditPercent = 0.26; break;
      case 2022: taxCreditPercent = 0.26; break;
      default: taxCreditPercent = 0; break;
    };
    const taxCredit = Math.round(total * taxCreditPercent);
    const netCost = total - taxCredit;
    const initialPayment = total * financing[financing_type.split('_')[0]].options[financing_type].paymentFactor;
    const reducedPayment = netCost * financing[financing_type.split('_')[0]].options[financing_type].paymentFactor;
    return {
      financing_type,
      years: financing[financing_type.split('_')[0]].options[financing_type].years,
      apr: ((financing[financing_type.split('_')[0]].options[financing_type].apr || 0) * 100),
      total,
      taxCreditPercent: taxCreditPercent * 100,
      taxCredit,
      netCost,
      initialPayment,
      reducedPayment
    };
  };

  function getKitTypeName(kit_type) {
    switch(kit_type){
      case 'grid-tied': return 'Grid Tied';
      case 'grid-tied-hybrid': return 'Grid Tied Hybrid';
      case 'off-grid': return 'Off Grid';
      default: return '';
    }
  }

  return {
    size: systemSize.kw,
    type: getKitTypeName(kit_type),
    price: pricing(),
    equipment: {
      batteries: add_batteries ? [battery_type].map(bat => batteries[bat].name) : [],
      panels: {
        type: panel ? panel.name : '',
        qty: systemSize.panels,
        cellType: panel ? panel.cellType : ''
      },
      inverters: {
        key: inverter_type,
        type: inverter ? `${inverter.name} ${inverter.type}` : '',
        qty: systemSize.inverters
      }
    }
  };
};
