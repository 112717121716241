
import moment from 'moment/moment';

import phoneFormatter from '../utils/phone-formatter';

function formatNumber(num: number) {
  num = +num.toFixed(2);
  return num.toLocaleString();
}

export default function({bid, user_input}: any){
  return `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>NEA Bid Tool</title>
  <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap" rel="stylesheet">
  <style>
    @media all {
      @page {
        size: letter landscape;
        orientation: landscape;
        margin: 0;
      }
      html{
        font-size: 10px; /* Do not change font-size, it will impact margins and paddings */
        /* height: 100%; */
      }
      body {
        /* height: 100%; */
        margin: 0;
        font-family: 'Montserrat', sans-serif;
        /* font-size: 165%; */
        /* Use if smart-shrinking ISN'T disabled in wkhtmltopdf tool */
        font-size: 200%;
        font-weight: 400;
      }
      * {
        box-sizing: border-box;
      }
      a {
        text-decoration: none;
        color: initial;
      }
      .separator {
        border-bottom: 1px solid #000000;
      }
      .info-text {
        font-size: 0.76em;
        font-weight: 300;
        margin: 0;
      }
      .call-to-action {
        display: block;
        text-decoration: none;
        color: inherit;
        background-color: #eeeeee;
        border: 1px solid #cccccc;
        border-radius: 4px;
        padding: 0.3em 1.2em;
      }
      .call-to-action p {
        text-align: center;
        line-height: 1.2;
        margin: 0;
      }
      .call-to-action p b {
        font-size: 1.6em;
        font-weight: 700;
      }
      .like-table {
        display: table;
      }
      .like-table>.like-table-cell {
        display: table-cell;
        vertical-align: middle;
      }
      .full-page {
        /* width: 11in; */
        width: 100%;
        /* Never use with wkhtmltopdf tool */
        /* height: 100%; */
        /* Use if smart-shrinking IS disabled and extra paddings are SET to zero in wkhtmltopdf tool */
        /* height: 8.5in; */
        /* Use if smart-shrinking ISN'T disabled but extra paddings are SET to zero in wkhtmltopdf tool */
        /* height: 10.63in; */
        /* Use if smart-shrinking ISN'T disabled and extra paddings are NOT SET to zero in wkhtmltopdf tool */
        height: 10.13in;
        page-break-after: always;
      }
      .cover-page {
        background-size: cover;
        background-position: center center;
        text-align: center;
        position: relative;
      }
      .cover-page::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .cover-page .content-box {
        display: inline-block;
        position: relative;
        width: 44%;
      }
      .page-0-container {
        background-position: center 60%;
        background-image: url("https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/backgrounds/house.jpg");
      }
      .page-0-container .content-box {
        background-color: rgba(255,255,255,0.9);
        width: 52%;
        padding: 4rem 5rem;
      }

      .page-0-container .content-box .logo {
        display: block;
        margin: 0 auto;
        width: 68%;
        padding: 6rem 0 8rem;
      }

      .page-0-container .content-box .label {
        margin: 1.8rem 0 4rem;
        text-align: center;
        font-size: 0.8em;
        font-weight: 700;
      }
      .page-0-container .content-box .person-name {
        font-size: 2.6em;
        font-weight: 400;
        margin: 1.4rem 0 2rem;
      }
      .page-0-container .content-box .small-meta {
        font-size: 0.8em;
        font-weight: 400;
        margin: 1.6rem 0;
        color: #666666;
      }
      .page-0-container .content-box .small-meta:last-child{
        margin-bottom: 0;
      }
      .side-panel-layout {
        height: 100%;
        width: 100%;
        line-height: 0;
        letter-spacing: 0;
      }
      .side-panel-layout .layout-aside,
      .side-panel-layout .layout-content {
        height: 100%;
        vertical-align: middle;
      }
      .side-panel-layout .layout-aside {
        float: left;
        width: 33%;
        position: relative;
        background-size: cover;
        background-position: center center;
      }
      .side-panel-layout .layout-aside::after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: url('https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/logos/solar-wholesale/white-horiz.png');
        background-position: center 92%;
        background-size: 68%;
        background-repeat: no-repeat;
      }
      .side-panel-layout .layout-content {
        width: 66%;
        line-height: 1.375;
        float: right;
      }
      .side-panel-layout .layout-content .content-box {
        padding: 4rem 2.8rem 2rem;
      }
      .section-heading {
        font-size: 2.85em;
        font-weight: 300;
        text-align: center;
        margin: 0;
        margin-bottom: 0.25em;
      }
      .section-heading .subheading {
        font-size: 0.36em;
        font-weight: 700;
        margin: 0;
      }
      .divider {
        border: none;
        border-top: 1px solid #000000;
      }
      .section-content {
        margin-top: 2em;
      }
      .section-content .list-item{
        padding-left: 4.8em;
        line-height: 1.375;
        position: relative;
        margin-bottom: 1.8em;
      }
      .section-content .list-item .floating-icon {
        width: 3.2em;
        height: 3.2em;
        position: absolute;
        left: 0;
        top: -0.25em;
      }
      .section-content .list-item .icon {
        opacity: 0.35;
        max-width: 100%;
      }
      .section-content .list-item .description {
        font-size: 1.5em;
        font-weight: 700;
        margin: 0;
      }
      .section-content .list-item .details {
        padding-left: 1.4em;
        margin: 0;
      }
      .section-content .list-item.no-icon {
        padding-left: 0;
      }
      .section-content .list-item.compact{
        margin-bottom: 0.8em;
      }
      .section-content .list-item.compact .details{
        padding-left: 0;
      }
      .page-7-container {
        background-image: url("https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/backgrounds/house-4.jpg");
        background-position: center bottom;
        color: #ffffff;
      }
      .page-7-container::before {
        background-color: rgba(0,0,0,0.6);
      }
      .page-7-container .content-box .icon {
        width: 40%;
      }
      .page-7-container .footer {
        position: absolute;
        padding: 3em;
        line-height: 1.375;
      }
      .page-7-container .footer .info-text {
        font-weight: 700;
      }
      .page-7-container .footer p {
        margin-bottom: 1em;
      }
      .page-7-container .footer p:last-child{
        margin-bottom: 0;
      }
      .page-7-container .footer-left{
        bottom: 0;
        left: 0;
        text-align: left;
      }
      .page-7-container .footer-right{
        bottom: 0;
        right: 0;
        text-align: right;
      }
      .page-5-container .side-panel-layout .layout-content .content-box {
        padding: 1em 2.8em 1em;
      }
      .page-5-container .section-content {
        margin-top: 1em;
      }
      .page-5-container table td {
        padding: 0.65em 0.5em;
      }
      .page-5-container table .col-text {
        margin: 0;
        font-size: 1.35em;
      }
      .page-5-container .table-small td {
        padding: 0.45em 0.5em;
      }
      .page-5-container .table-small .col-text {
        margin: 0;
        font-size: 1em;
        white-space: nowrap;
      }
      .page-5-container .call-to-action,
      .page-6-container .call-to-action {
        margin: 3.6rem auto 0;
        width: 78%;
      }
    }
  </style>
</head>
<body style="margin: 0;">
  <!-- BEGIN: Page 0 -->
  <div class="full-page cover-page like-table page-0-container">
    <div class="like-table-cell">
      <div class="content-box">
        <img class="logo" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/logos/solar-wholesale/black-horiz.png" alt="Solar wholesale logo" />
        <div class="separator"></div>
        <p class="label">ENERGY SAVINGS REPORT FOR</p>
        <div class="separator" style="width: 16%; margin: 0 auto;"></div>
        <h1 class="person-name">${user_input.first_name} ${user_input.last_name}</h1>
        <p class="small-meta">${user_input.address && (user_input.address.formatted_address)}</p>
        <p class="small-meta">${user_input.phone}</p>
        <p class="small-meta"><b>${user_input.email}</b></p>
      </div>
    </div>
  </div>
  <!-- END: Page 0 -->

  <!-- BEGIN: Page 1 -->
  <div class="full-page">
    <div class="side-panel-layout">
      <div class="layout-aside" style="background-image: url(https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/backgrounds/sunflowers.jpg)">
      </div>
      <div class="layout-content">
        <div class="content-box">
          <h1 class="section-heading">Why Solar?</h1>
          <hr class="divider" />

          <div class="section-content">
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-01.svg" alt="circle icon"/></div><p class="description">GREEN</p></div>
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-02.svg" alt="circle icon"/></div><p class="description">SAVE MONEY</p></div>
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-03.svg" alt="circle icon"/></div><p class="description">OWN YOUR POWER</p></div>
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-04.svg" alt="circle icon"/></div><p class="description">CONTROL & PREDICTABILITY</p></div>
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-05.svg" alt="circle icon"/></div><p class="description">POTENTIAL HOME VALUE INCREASE</p></div>
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-06.svg" alt="circle icon"/></div><p class="description">AVOID RISING RATES</p></div>
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-07.svg" alt="circle icon"/></div><p class="description">SUNSHINE IS FREE!</p></div>
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-08.svg" alt="circle icon"/></div><p class="description">SELF SUFFICIENT/PREPARED</p></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Page 1 -->

  <!-- BEGIN: Page 2 -->
  <div class="full-page">
    <div class="side-panel-layout">
      <div class="layout-aside" style="background-image: url(https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/backgrounds/house-2.jpg)">
      </div>
      <div class="layout-content">
        <div class="content-box">
          <h1 class="section-heading">Why DIY?</h1>
          <hr class="divider" />

          <div class="section-content">
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-09.svg" alt="circle icon"/></div><p class="description">REAL SAVINGS</p></div>
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-10.svg" alt="circle icon"/></div><p class="description">95% OF OUR CUSTOMERS SUCCESSFULLY INSTALL THEIR KIT WITHIN 30 DAYS</p></div>
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-11.svg" alt="circle icon"/></div><p class="description">QUALITY OF WORK</p></div>
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-12.svg" alt="circle icon"/></div><p class="description">BECAUSE YOU CAN!!!</p></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Page 2 -->

  <!-- BEGIN: Page 3 -->
  <div class="full-page">
    <div class="side-panel-layout">
      <div class="layout-aside" style="background-image: url(https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/backgrounds/house-3.jpg)">
      </div>
      <div class="layout-content">
        <div class="content-box">
          <h1 class="section-heading">Why Solar Wholesale?</h1>
          <hr class="divider" />

          <div class="section-content">
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-13.svg" alt="circle icon"/></div><p class="description">WHOLESALE PRICING</p></div>
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-14.svg" alt="circle icon"/></div><p class="description">SOLAR IN A BOX</p></div>
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-15.svg" alt="circle icon"/></div><p class="description">HIGHEST SUCCESS RATE</p></div>
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-16.svg" alt="circle icon"/></div><p class="description">EDUCATION</p></div>
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-17.svg" alt="circle icon"/></div><p class="description">RESOURCES</p></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Page 3 -->

  <!-- BEGIN: Page 4 -->
  <div class="full-page">
    <div class="side-panel-layout">
      <div class="layout-aside" style="background-image: url(https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/backgrounds/panels.jpg)">
      </div>
      <div class="layout-content">
        <div class="content-box">
          <h1 class="section-heading">Solar in a Box</h1>
          <hr class="divider" />

          <div class="section-content">
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-18.svg" alt="circle icon"/></div><p class="description">${bid.equipment.panels.qty} - ${bid.equipment.panels.type.toUpperCase()} WATT ${bid.equipment.panels.cellType.toUpperCase()} PANEL${bid.equipment.panels.qty > 1 ? 'S': ''}</p></div>
          <!-- <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-19.svg" alt="circle icon"/></div><p class="description">AP SYSTEMS MICRO INVERTERS (25 YEAR WARRANTY)</p></div> -->
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-20.svg" alt="circle icon"/></div><p class="description">SNAP’N’RACK MOUNT AND RAIL SYSTEM</p></div>
          <div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-21.svg" alt="circle icon"/></div><p class="description">${bid.equipment.inverters.type.substr(-6, 6) === 'string' && bid.equipment.inverters.qty > 1 ? bid.equipment.inverters.qty + ' - ' : ''}${bid.equipment.inverters.type.toUpperCase()} INVERTER${bid.equipment.inverters.qty > 1 ? 'S': ''}</p></div>
          ${bid.equipment.batteries.length ? (
            `<div class="list-item"><div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-22.svg" alt="circle icon"/></div><p class="description">${bid.equipment.batteries[0].toUpperCase()}</p></div>`
          ) : ''}
            <div class="list-item">
              <div class="floating-icon"><img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/IconSet-23.svg" alt="circle icon"/></div>
              <p class="description">BALANCE OF SYSTEM</p>
              <p class="details">
                THIS INCLUDES WIRING, SWITCHES, MOUNTING SYSTEM,
                INVERTERS,${bid.equipment.batteries.length ? ' BATTERIES,' : ''} AND ALL OTHER COMPONENTS
                NEEDED FOR YOUR SPECIFIC SOLAR SYSTEM.
              </p>
            </div>
            <!-- <div class="list-item">
              <p class="info-text">
                *Each kit is customized for individual needs and may contain different components.
                Sol-Ark and Fortress Batteries are not included in a standard DIY Kit.
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Page 4 -->

  <!-- BEGIN: Page 5 -->
  <div class="full-page page-5-container">
    <div class="side-panel-layout">
      <div class="layout-aside" style="background-image: url(https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/backgrounds/house-3.jpg)">
      </div>
      <div class="layout-content">
        <div class="content-box">
          <h1 class="section-heading">
            Your DIY Kit
            <p class="subheading">${bid.size.toFixed(2)} KW <span style="letter-spacing: 0.2em;">${bid.type.toUpperCase()} SYSTEM</span></p>
          </h1>
          <hr class="divider" />

          <div class="section-content">
            <table>
              <tr>
                <td><p class="col-text">INITIAL SYSTEM COST</p></td>
                <td><p class="col-text">$${formatNumber(bid.price.total)}</p></td>
              </tr>
              <tr>
                <td><p class="col-text">${bid.price.taxCreditPercent}% FEDERAL TAX CREDIT</p></td>
                <td><p class="col-text">$${formatNumber(bid.price.taxCredit)}</p></td>
              </tr>
              <!-- <tr>
                <td>
                  <p class="col-text">ADDITIONAL INCENTIVES</p>
                  <p class="info-text">
                    Additional incentives may be available f rom your state, utility, or other sources.
                    Not all incentives are identified by Solar Wholesale.
                    Applying for and collecting incentives is up to the customer.
                  </p>
                </td>
                <td><p class="col-text">$0</p></td>
              </tr> -->
              <tr>
                <td><p class="col-text">NET SYSTEM COST</p></td>
                <td><p class="col-text">$${formatNumber(bid.price.netCost)}</p></td>
              </tr>
              ${bid.price.financing_type !== 'cash' ? (
                `<tr>
                  <td>
                    <p class="col-text"><b>FINANCE OPTIONS</b></p>
                    <p class="info-text">
                      Finance options vary by market and are based on credit approval.
                      Reach out to learn more about your financing options.
                    </p>
                  </td>
                </tr>`
              ) : ''}
            </table>
            ${bid.price.financing_type !== 'cash' ? (
              `<table class="table-small">
                <tr>
                  <td>
                    <p class="col-text">TERM</p>
                  </td>
                  <td>
                    <p class="col-text">${bid.price.years} years</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p class="col-text">RATE</p>
                  </td>
                  <td>
                    <p class="col-text">${bid.price.apr.toFixed(2)}%</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p class="col-text">INITIAL MONTHLY PAYMENT</p>
                  </td>
                  <td>
                    <p class="col-text">$${formatNumber(bid.price.initialPayment)}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p class="col-text">REDUCED MONTHLY PAYMENT*</p>
                    <p class="info-text">
                      *Reduced monthly payments require incentives or additional payments within an allotted time period to acheive lowest possible payment.
                    </p>
                  </td>
                  <td>
                    <p class="col-text">$${formatNumber(bid.price.reducedPayment)}</p>
                  </td>
                </tr>
              </table>`
            ) : ''}

            <a class="call-to-action" href="${user_input.salesRep ? user_input.salesRep.calendarLink: '#'}" target="_BLANK">
              <p>
                TO SCHEDULE A TIME TO GO OVER YOUR PROPOSAL WITH ONE OF OUR PROFESSIONALS
                <br />
                <b>CLICK HERE</b>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Page 5 -->

  <!-- BEGIN: Page 6 -->
  <div class="full-page page-6-container">
    <div class="side-panel-layout">
      <div class="layout-aside" style="background-image: url(https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/backgrounds/green-sun.jpg)">
      </div>
      <div class="layout-content">
        <div class="content-box">
          <h1 class="section-heading">
            NEXT STEPS
            <p class="subheading"><b>How to get started on your DIY Solar Project</b></p>
          </h1>
          <hr class="divider" />

          <div class="section-content">
            <div class="list-item compact no-icon">
              <p class="description">ORDER YOUR PLAN SET</p>
              <p class="details">
                A solar plan set is the blueprint for your system and installation. It
                details the layout, components, electrical mapping, and all details
                for your specific project. A plan set is required to obtain permits
                from your utility and local jurisdiction.
              </p>
            </div>
            <div class="list-item compact no-icon">
              <p class="description">CHOOSE YOUR SHIPPING DATE</p>
              <p class="details">
                When you order your plan set you will be asked to choose a date for
                your kit to be shipped to you. Payment or approved financing will be
                required prior to your kit being shipped.
              </p>
            </div>
            <div class="list-item compact no-icon">
              <p class="description">COMPLETE YOUR DIY SITE SURVEY</p>
              <p class="details">
                Once a plan set is ordered you will receive instructions on how to
                complete a site survey of your home. This provides our designers and
                engineers the information needed to build your plan set.
              </p>
            </div>
            <div class="list-item compact no-icon">
              <p class="description">INSTALL YOUR SYSTEM</p>
              <p class="details">
                Once all required permits have been obtained, it’s time to install! To
                make it easy you will be provided a step by step install guide and have
                access to our resource center. If at any point you get stuck our guides,
                videos, instruction manuals, and real life professionals are here to help
                you install your own solar!
              </p>
            </div>

            <a class="call-to-action" href="${user_input.salesRep.calendarLink}" target="_BLANK">
              <p>
                TO SCHEDULE A TIME TO GO OVER YOUR PROPOSAL WITH ONE OF OUR PROFESSIONALS
                <br />
                <b>CLICK HERE</b>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Page 6 -->

  <!-- BEGIN: Page 7 -->
  <div class="full-page cover-page like-table page-7-container" >
    <div class="like-table-cell">
      <div class="content-box">
        <img class="icon" src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/logos/solar-wholesale/white-stacked.png" alt="Solar wholesale logo" />
      </div>
    </div>

    <div class="footer footer-left">
      <p class="info-text">*This bid is valid until ${moment().add(30, 'days').format('dddd MMM Do, YYYY')}</p>
      <p class="info-text">Proposal created by ${user_input.salesRep.firstName} ${user_input.salesRep.lastName}.</p>
    </div>
    <div class="footer footer-right">
      <p>${user_input.salesRep.firstName} ${user_input.salesRep.lastName}</p>
      <p>
        Office Main: +1 (800) 661-6027
        <br />
        Personal: ${phoneFormatter(user_input.salesRep.phoneCell)}
      </p>
      <p>
        ${user_input.salesRep.email}
        <br />
        www.SolarWholesale.com
      </p>
      <p>
        8152 Welby Park Dr
        <br />
        West Jordan, UT 84088
      </p>
    </div>
  </div>
  <!-- END: Page 7 -->
</body>
</html>
`}
