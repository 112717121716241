
import phoneFormatter from '../utils/phone-formatter';

export default (user_input: any) => ([
`<p style="text-align:left;"></p>`,
`<img src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/logos/solar-wholesale/black-horiz.png" alt="Solar Wholesale logo" style="display: block; height: auto; width: 300px; margin: auto;"/>`,
`<br/>`,
`<p style="text-align:center;"><strong>Hey ${user_input.first_name} ${user_input.last_name}!</strong></p>`,
`<br/>`,
`<p style="text-align:center;">Attached is your Solar Wholesale DIY Kit Information.</p>`,
`<p style="text-align:center;">Your DIY expert is:<br/>${user_input.salesRep.firstName} ${user_input.salesRep.lastName}<br/>${phoneFormatter(user_input.salesRep.phoneCell) || '--'}<br/>${user_input.salesRep.email}</p>`,
`<br/>`,
`<p style="text-align:center;">If you have any questions about your proposed SOLAR-IN-A-BOX kit from Solar Wholesale, feel free to email or text your DIY expert, or if you'd like to schedule a time for a phone call, you can do so by <a href="${user_input.salesRep.calendarLink}">clicking here.</a></p>`,
`<p style="text-align:center;">Our SOLAR-IN-A-BOX kits include panels, inverter/inverters, racking, breakers, junction boxes, conduit, stickers/placards, wiring, trunk cables, zip ties, electrical tape, wire nuts, drill bits, 1/2” socket, sealant, etc. <a href="https://youtu.be/D3byOL6K3cw">Click here</a> to learn why our kits are different.</p>`,
`<p style="text-align:center;">If you are ready to move forward, <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=HUZG6F2GEFBFC">Click here</a> to pay $500 and order your solar plan set and engineering for your project. This $500 goes towards your total kit price and will give you everything you need to start your permitting and interconnection process. A plan set details the design, layout, and electrical diagram of your system. It also details each component to be used in the installation, all of which will be included in your SOLAR-IN-A-BOX kit and shipped right to your door! A plan set is required to submit for utility and jurisdictional permits, which are required to install solar on most homes. Once your deposit is paid you will receive an individualized link where you can upload your site survey information and have access to everything you will need to complete your project. Kits are typically shipped out 2 weeks after you place your order unless you request otherwise. If you would like to select a different ship date, you can do that by clicking <a href="https://calendly.com/emily-sw/target-ship-date">here</a>.</p>`,
`<p style="text-align:center;">Please let your DIY expert know if you have any questions and we look forward to helping you see your solar project come to life!</p>`,
`<p style="text-align:center;">Cheers to a greener, cheaper, future.</p>`,
`<br/><br/>`,
`<p style="text-align:center;"><strong>Stay Connected!</strong></p>`,
`<img src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/social-media-icon-row.png" alt="Social Media Icons" style="display: block; margin: auto;"/>`,
`<br/>`,
`<p style="text-align:center;"><strong>(800) 661-6027 | customercare@solarwholesale.com</strong><br>8152 S. Welby Park Rd West Jordan UT, 84088</p>`,
`<img src="https://solar-wholesale.s3-us-west-2.amazonaws.com/resources/icons/google-review.jpg" alt="Google Review" style="display: block; height: auto; width: 120px; margin: auto;"/>`,
`<p style="text-align:center;"><a href="https://www.google.com/search?q=solarwholesale&rlz=1C1GCEA_enUS874US874&oq=solarwholesale&aqs=chrome..69i57j0j69i59j69i60j69i64l3j69i60.2711j0j7&sourceid=chrome&ie=UTF-8%23lrd=0x87528fc0cacecc7d:0xd53e9a7063532389,1,,,/view">Click Here</a> to see our Google reviews!</p>`,
`<p></p>`

]).join('');
