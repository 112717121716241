
import React, { useCallback, Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { pointOnCircle } from './utils';
import styles from './styles';

type Option = { label: string; value: any; };

type DirectionInputProps = {
  options?: Option[],
  name?: string;
  value?: string;
  onChange?: (val: string)=>void;
  onBlur?: (e: any)=>void;
  error?: boolean;
  helperText?: boolean | string;
  disabled?: boolean;
  size?: number;
};

const useStyles = makeStyles(styles);

const DirectionInput: React.FC<DirectionInputProps> = function(props) {
  const {
    options = [],
    value,
    onChange = ()=>null,
    disabled,
    // error,
    // helperText,
    size = 400
  } = props;

  const [needleAngle, setNeedleAngle] = useState(0);
  const classes = useStyles();

  const centerPoint = {x: size/2, y: size/2};
  const fullRadius = size/2;
  const stopsRadius = fullRadius - size * 0.12;
  const labelsRadius = fullRadius - size * 0.05;
  const needleRadius = fullRadius - size * 0.25;

  const getStopLine = useCallback((angle)=>{
    const outerPoint = pointOnCircle(centerPoint.x, centerPoint.y, stopsRadius, angle);
    const innerPoint = pointOnCircle(centerPoint.x, centerPoint.y, stopsRadius - 10, angle);

    return {
      x1: outerPoint.x,
      y1: outerPoint.y,
      x2: innerPoint.x,
      y2: innerPoint.y
    };

  }, [centerPoint, stopsRadius]);

  useEffect(()=>{
    const optionIndex = options.map(opt=>opt.value).indexOf(value);
    const needleAngle = 360 / options.length * optionIndex;
    setNeedleAngle(needleAngle);
  }, [value, options]);


  return (
    <div className={classes.root}>
      <svg height={size} width={size}>
        {options.map((opt, i)=>{
          const angle = 360 / options.length * i;
          const line = getStopLine(angle);
          const textPoint = pointOnCircle(centerPoint.x, centerPoint.y, labelsRadius, angle);
          const anchorPoint = pointOnCircle(centerPoint.x, centerPoint.y, stopsRadius - 25, angle);

          return (
            <Fragment key={''+i}>
              <text className={clsx(classes.label, opt.value === value ? 'active' : undefined)}
                x={textPoint.x} y={textPoint.y} textAnchor="middle" dominantBaseline="middle"
              >
                {opt.label}
              </text>
              <line className={clsx(classes.stop, opt.value === value ? 'active' : undefined)}
                x1={line.x1} y1={line.y1} x2={line.x2} y2={line.y2}
              />
              <circle className={clsx(classes.anchorPoint, opt.value === value ? 'active' : undefined)}
                cx={anchorPoint.x} cy={anchorPoint.y} r={6}
                onClick={()=>!disabled && onChange(opt.value)}
              />
            </Fragment>
          );
        })}
        <polygon className={classes.needle}
          points={`${centerPoint.x - 35},${centerPoint.y} ${centerPoint.x},${centerPoint.y - 15} ${centerPoint.x + needleRadius},${centerPoint.y} ${centerPoint.x},${centerPoint.y + 15} ${centerPoint.x - 35},${centerPoint.y}`}
          transform={`rotate(${needleAngle}, ${centerPoint.x}, ${centerPoint.y})`}
        />
        <circle cx={centerPoint.x} cy={centerPoint.y} r="3" fill="#ffffff" />
      </svg>
    </div>
  );
};

export default DirectionInput;
