
import React from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

export interface FormDataSummaryProps {
  data: any;
}

const FormDataSummary: React.FC<FormDataSummaryProps> = function(props) {
  return (
    <div>
      <TableContainer>
        <Table>
          <TableBody>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>{props.data.first_name} {props.data.last_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>{props.data.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Phone</TableCell>
            <TableCell>{props.data.phone}</TableCell>
          </TableRow>
            {props.data.address && (
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>{props.data.address.formatted_address}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Roof direction</TableCell>
              <TableCell>{props.data.roof_direction}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Utility provider</TableCell>
              <TableCell>{props.data.utility_provider || '--'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Roof/Installation type</TableCell>
              <TableCell>{props.data.roof_type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Panel</TableCell>
              <TableCell>{props.data.panel_type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kit type</TableCell>
              <TableCell>{props.data.kit_type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Inverter</TableCell>
              <TableCell>{props.data.inverter_type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Add batteries</TableCell>
              <TableCell>{props.data.add_batteries ? 'Yes' : 'No'}</TableCell>
            </TableRow>
            {!!props.data.add_batteries && (
              <TableRow>
                <TableCell>Battery</TableCell>
                <TableCell>{props.data.battery_type}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>Utility offset</TableCell>
              <TableCell>{props.data.offset}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Panel adjustment</TableCell>
              <TableCell>{props.data.panel_adjustment}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Financing</TableCell>
              <TableCell>{props.data.financing_type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>System size from</TableCell>
              <TableCell>{props.data.system_size_type}</TableCell>
            </TableRow>
            {props.data.system_size_type === 'usage' && (
              <TableRow>
                <TableCell>Avg. Monthly usage</TableCell>
                <TableCell>{props.data.average_bill.usage}kw @ {props.data.average_bill.payment}USD</TableCell>
              </TableRow>
            )}
            {props.data.system_size_type === 'sqfootage' && (
              <TableRow>
                <TableCell>Square footage</TableCell>
                <TableCell>{props.data.sq_footage}</TableCell>
              </TableRow>
            )}
            {props.data.system_size_type === 'known' && (
              <TableRow>
                <TableCell>System size</TableCell>
                <TableCell>{props.data.system_size.key}: {props.data.system_size.value}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default FormDataSummary;
